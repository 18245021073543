import { TextInput, CopyButton, Button } from '@mantine/core';
import React from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';

interface Props {
  gameId: string;
  label?: string;
  autoFocus?: boolean;
}

export default function CopyRoomCodeButton({
  gameId,
  label,
  autoFocus,
}: Props) {
  function getGameUrl() {
    return `${process.env.REACT_APP_PUBLIC_URL}/?join=${gameId}`;
  }

  return (
    <TextInput
      autoFocus={autoFocus}
      onFocus={(event) => event.target.select()}
      readOnly
      value={getGameUrl()}
      label={label}
      rightSection={
        <CopyButton value={getGameUrl()}>
          {({ copied, copy }) => (
            <Button
              color={copied ? 'green' : 'blue'}
              onClick={copy}
              className="mr-4"
            >
              {copied ? <FaCheck /> : <FaCopy />}
            </Button>
          )}
        </CopyButton>
      }
    ></TextInput>
  );
}
