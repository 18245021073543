import { Button, Checkbox, Flex, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import React, { FormEvent, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import {
  ObjectiveData,
  PlayerData,
  PlayerGameData,
  PublicGameData,
} from '../../types/game';
import Objective from './Objective';

interface Props {
  game: PublicGameData;
  player: PlayerData;
  isComplete: (objective: ObjectiveData) => boolean;
  setHoveredObjective: (objective: ObjectiveData | null) => void;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function PendingObjectives({
  game,
  player,
  isComplete,
  setHoveredObjective,
  setGameData,
  setPlayerData,
}: Props) {
  const [checkedObjectives, checkedObjectivesHandles] =
    useListState<ObjectiveData>([]);
  const [loading, setLoading] = useState(false);

  function sameObjective(a: ObjectiveData, b: ObjectiveData) {
    return a.cityA === b.cityA && a.cityB === b.cityB;
  }

  function toggleObjective(objective: ObjectiveData) {
    if (checkedObjectives.find((x) => sameObjective(objective, x))) {
      checkedObjectivesHandles.filter((x) => !sameObjective(objective, x));
    } else {
      checkedObjectivesHandles.append(objective);
    }
  }

  const { pendingObjectives, objectives } = player;
  const requiredTickets = objectives.length === 0 ? 2 : 1;

  async function chooseSelected(ev: FormEvent) {
    ev.preventDefault();
    setLoading(true);
    try {
      const gameData = await apiPost<PlayerGameData>(
        `game/${game.id}/choose-objectives`,
        {
          objectives: checkedObjectives,
          playerCode: player.code,
        }
      );
      setGameData(gameData.game);
      setPlayerData(gameData.player);
    } catch (error) {}
    checkedObjectivesHandles.setState([]);
    setLoading(false);
  }

  return (
    <form onSubmit={chooseSelected} className="max-w-xl">
      <Text size="lg" weight="bold" className="my-2">
        Pending objectives:
      </Text>
      {pendingObjectives.map((objective, idx) => (
        <div
          className="mb-2 cursor-pointer"
          key={idx}
          onMouseEnter={() => setHoveredObjective(objective)}
          onMouseLeave={() => setHoveredObjective(null)}
          onClick={() => toggleObjective(objective)}
        >
          <Objective objective={objective} complete={isComplete(objective)}>
            <Checkbox
              checked={
                checkedObjectives.find((x) => sameObjective(objective, x)) !==
                undefined
              }
              color="yellow"
              readOnly
            ></Checkbox>
          </Objective>
        </div>
      ))}
      <Flex justify="center">
        <Button
          loading={loading}
          leftIcon={<FaCheck />}
          type="submit"
          disabled={checkedObjectives.length < requiredTickets}
          color="yellow"
        >
          Confirm chosen tickets (min {requiredTickets})
        </Button>
      </Flex>
    </form>
  );
}
