import React from 'react';
import { Text } from '@mantine/core';
import Linkify from 'react-linkify';
import {
  CardColor,
  ChatMessageData,
  EventChosenObjectives,
  EventPickObjectives,
  EventPickTrains,
  EventPlaceStation,
  EventPlaceTrains,
} from '../../types/game';
import { FaTimes } from 'react-icons/fa';

interface Props {
  message: ChatMessageData;
}

export default function ChatMessage({ message }: Props) {
  function formatPlayer() {
    return (
      <Text span weight="bold">
        {message.player}
      </Text>
    );
  }

  function formatCards(
    cards: CardColor[],
    unknownCards = 0,
    icon?: React.ReactNode
  ) {
    return (
      <span className="inline-flex align-middle pb-1">
        {cards.map((card, idx) => (
          <div
            key={idx}
            className={`h-6 w-4 shown-card-${card} mr-1 border rounded-sm flex items-center justify-center`}
          >
            {icon}
          </div>
        ))}
        {[...Array(unknownCards)].map((card, idx) => (
          <div
            key={idx + cards.length}
            className={`h-6 w-4 shown-card-white mr-1 border rounded-sm flex items-center justify-center`}
          >
            ?
          </div>
        ))}
      </span>
    );
  }

  function formatEvent() {
    switch (message.event!.type) {
      case 'pick-trains': {
        const { shownPicks, deckPicks } = message.event as EventPickTrains;
        const pickedCards: string[] = shownPicks.map((x) => `${x} train`);
        if (deckPicks > 0) {
          pickedCards.push(`${deckPicks} train(s) from the deck`);
        }
        return (
          <>
            {formatPlayer()} picked {formatCards(shownPicks, deckPicks)}
          </>
        );
      }
      case 'pick-objectives': {
        const { count } = message.event as EventPickObjectives;
        return (
          <>
            {formatPlayer()} picked {count} objectives
          </>
        );
      }
      case 'place-trains': {
        const { track, usedCards } = message.event as EventPlaceTrains;
        const { cityA, cityB, color } = track;
        return (
          <>
            {formatPlayer()} placed {color} track {cityA} - {cityB} using{' '}
            {formatCards(usedCards, 0, <FaTimes />)}
          </>
        );
      }
      case 'place-station':
        const { city, usedCards } = message.event as EventPlaceStation;
        return (
          <>
            {formatPlayer()} placed a station on {city} using{' '}
            {formatCards(usedCards, 0, <FaTimes />)}
          </>
        );
      case 'chosen-objectives': {
        const { count } = message.event as EventChosenObjectives;
        return (
          <>
            {formatPlayer()} kept {count} objective(s) and discarded the others
          </>
        );
      }
      default:
        return <>{message.text}</>;
    }
  }

  if (message.event === null) {
    return (
      <Text size="sm" className="break-words">
        {formatPlayer()}: <Linkify>{message.text}</Linkify>
      </Text>
    );
  }

  return (
    <Text size="xs" align="center" className="break-words text-gray-700 group">
      {formatEvent()}
    </Text>
  );
}
