import React, { useEffect, useState } from 'react';
import {
  Alert,
  Container,
  Flex,
  Loader,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import Chat from './chat/Chat';
import Players from './players/Players';
import GameMap from './map/GameMap';
import {
  CityData,
  GameOverDataObjective,
  ObjectiveData,
  playerColors,
  PlayerData,
  PlayerGameData,
  PublicGameData,
  TrackData,
} from '../types/game';
import io from 'socket.io-client';
import { socketEmit, socketHandle } from '../services/socket-service';
import { useParams, useSearchParams } from 'react-router-dom';
import { apiGet, ApiException } from '../services/api-service';
import { FaExclamationTriangle } from 'react-icons/fa';
import Inventory from './inventory/Inventory';
import Decks from './decks/Decks';
import ScoreReveal from './score-reveal/ScoreReveal';

const socket = io(process.env.REACT_APP_WEBSOCKET_URL as string, {
  autoConnect: false,
  path: process.env.REACT_APP_WEBSOCKET_PATH,
});

export default function GameLayout() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [gameData, setGameData] = useState<PublicGameData | null>(null);
  const [playerData, setPlayerData] = useState<PlayerData | null>(null);
  const [hoveredObjective, setHoveredObjective] =
    useState<ObjectiveData | null>(null);
  const [selectedTrack, setSelectedTrack] = useState<TrackData | null>(null);
  const [selectedCity, setSelectedCity] = useState<CityData | null>(null);
  const [pinnedObjectives, setPinnedObjectives] = useState<ObjectiveData[]>([]);
  const [revealedObjective, setRevealedObjective] =
    useState<GameOverDataObjective | null>(null);

  // handle socket connection and initial load
  useEffect(() => {
    const gameId = params.id;
    const playerCode = searchParams.get('player');
    if (!gameId || !playerCode) {
      return;
    }

    if (!socket.connected) {
      socket.connect();
    }
    socket.on('connect', () => {
      setIsConnected(true);
      socketEmit('join-room', socket, {
        gameId,
        playerCode,
      });
      loadGameData();
    });
    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    async function loadGameData() {
      setLoading(true);
      try {
        const data = await apiGet<PlayerGameData>(
          `game/${gameId}?player=${playerCode}`
        );
        setGameData(data.game);
        setPlayerData(data.player);
        setLoading(false);
      } catch (error) {
        if (error instanceof ApiException) {
          setError(error.message);
        } else {
          setError('Something went wrong, please try refreshing the page');
        }
        setLoading(false);
        return;
      }
    }

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.disconnect();
    };
  }, [params, searchParams]);

  // update the state as required
  useEffect(() => {
    socketHandle('new-game-state', socket, (data) => {
      setGameData(data);
    });
    socketHandle('new-player-state', socket, (data) => {
      setPlayerData(data);
    });
    return () => {
      socket.off('new-game-state');
      socket.off('new-player-state');
    };
  }, []);

  // display errors
  if (error) {
    return (
      <Container size="xs" className="py-2">
        <Alert
          icon={<FaExclamationTriangle size={16} />}
          title="Something went wrong"
          color="red"
        >
          {error}
        </Alert>
      </Container>
    );
  }
  // display loading
  if (!gameData || !playerData) {
    return (
      <Text size="lg" className="p-2">
        Loading game data... <Loader size="sm" />
      </Text>
    );
  }

  // actual game layout
  let playersHeight =
    20 + 70 * gameData.players.length + 8 * (gameData.players.length - 1);
  if (gameData.players.length < playerColors.length || gameData.isOver) {
    playersHeight += 60; // more room for the invite button
  }
  return (
    <Flex
      style={{
        height: 'calc(max(100vh, 940px))',
        width: 'calc(max(100%, 1700px))',
      }}
    >
      <LoadingOverlay visible={loading}></LoadingOverlay>
      <div className="border-r border-gray-600 overflow-hidden flex-grow basis-60 max-w-sm">
        {/* players and chat */}
        <div style={{ height: `${playersHeight}px` }}>
          <Players
            currentPlayer={playerData}
            socket={socket}
            game={gameData}
            setGameData={setGameData}
            setPlayerData={setPlayerData}
          />
        </div>
        <div
          style={{
            height: `calc(max(100vh, 940px) - ${playersHeight}px)`,
          }}
        >
          <Chat socket={socket} isConnected={isConnected} />
        </div>
      </div>
      {/* game */}
      <div>
        <GameMap
          setSelectedCity={setSelectedCity}
          selectedTrack={selectedTrack}
          setSelectedTrack={setSelectedTrack}
          hoveredObjective={hoveredObjective}
          selectedCity={selectedCity}
          game={gameData}
          player={playerData}
          pinnedObjectives={pinnedObjectives}
          revealedObjective={revealedObjective}
          setGameData={setGameData}
          setPlayerData={setPlayerData}
        />
        <div
          className="border-t border-gray-600"
          style={{ height: 'calc(max(100vh, 940px) - 780px)' }}
        >
          {!gameData.isOver && (
            <Decks
              setGameData={setGameData}
              setPlayerData={setPlayerData}
              game={gameData}
              currentPlayer={playerData}
            />
          )}
          {gameData.isOver && (
            <ScoreReveal
              socket={socket}
              game={gameData}
              currentPlayer={playerData}
              revealedObjective={revealedObjective}
              setRevealedObjective={setRevealedObjective}
            />
          )}
        </div>
      </div>
      {/* inventory */}
      <div className="border-l border-gray-600 flex-grow basis-80">
        <Inventory
          selectedTrack={selectedTrack}
          clearSelectedTrack={() => setSelectedTrack(null)}
          setHoveredObjective={setHoveredObjective}
          isConnected={isConnected}
          player={playerData}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          game={gameData}
          pinnedObjectives={pinnedObjectives}
          setPinnedObjectives={setPinnedObjectives}
          setGameData={setGameData}
          setPlayerData={setPlayerData}
        />
      </div>
    </Flex>
  );
}
