import { MantineProvider } from '@mantine/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MapEditor from './app/game/map-editor/MapEditor';
import GameLayout from './app/game/GameLayout';
import './index.css';
import RoomJoiner from './app/room-joiner/RoomJoiner';
import { NotificationsProvider } from '@mantine/notifications';
import RoomJoinerShell from './app/room-joiner/RoomJoinerShell';

export default function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <NotificationsProvider position="top-right">
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route path="/editor" element={<MapEditor />} />
            <Route element={<RoomJoinerShell />}>
              <Route path="/" element={<RoomJoiner />} />
            </Route>
            <Route path="/game/:id" element={<GameLayout />} />
          </Routes>
        </BrowserRouter>
      </NotificationsProvider>
    </MantineProvider>
  );
}
