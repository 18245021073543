import {
  Button,
  Checkbox,
  Flex,
  ScrollArea,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { FaCheck, FaDotCircle, FaPlus, FaThumbtack } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import { ObjectiveData, PlayerData, PublicGameData } from '../../types/game';
import Objective from './Objective';
import PendingObjectives from './PendingObjectives';

export interface Props {
  setHoveredObjective: (objective: ObjectiveData | null) => void;
  game: PublicGameData;
  player: PlayerData;
  pinnedObjectives: ObjectiveData[];
  setPinnedObjectives: (objectives: ObjectiveData[]) => void;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function Objectives({
  game,
  setHoveredObjective,
  player,
  pinnedObjectives,
  setPinnedObjectives,
  setGameData,
  setPlayerData,
}: Props) {
  const objectives = player.objectives;
  const pendingObjectives = player.pendingObjectives;
  // used to determine which cities are connected
  const [connectedComponents, setConnectedComponents] = useState<{
    [city: string]: string;
  }>({});

  const isComplete = useCallback(
    (objective: ObjectiveData) =>
      !!connectedComponents[objective.cityA] &&
      connectedComponents[objective.cityA] ===
        connectedComponents[objective.cityB],
    [connectedComponents]
  );

  const [hideCompleted, setHideCompleted] = useState(false);
  useEffect(() => {
    const myTracks = game.claimedTracks.filter(
      (x) => x.player === player.color
    );
    const myStations = game.stations.filter((x) => x.player === player.color);
    // union find disjoint set algorithm
    const ufdsParent: { [city: string]: string } = {};
    game.cities.forEach((city) => {
      ufdsParent[city.name] = city.name;
    });
    function ufdsGetParent(city: string): string {
      if (ufdsParent[city] === city) {
        return city;
      }
      return (ufdsParent[city] = ufdsGetParent(ufdsParent[city]));
    }
    function ufdsMerge(cityA: string, cityB: string) {
      ufdsParent[ufdsGetParent(cityA)] = ufdsGetParent(cityB);
    }
    myTracks.forEach((track) => {
      ufdsMerge(track.cityA, track.cityB);
    });
    myStations.forEach((station) => {
      if (station.toCity) {
        ufdsMerge(station.city, station.toCity);
      }
    });
    const newConnectedComponents: { [city: string]: string } = {};
    for (const city in ufdsParent) {
      if (Object.prototype.hasOwnProperty.call(ufdsParent, city)) {
        newConnectedComponents[city] = ufdsGetParent(city);
      }
    }
    setConnectedComponents(newConnectedComponents);
  }, [game.claimedTracks, player.color, game.stations, game.cities]);
  useEffect(() => {
    const newPinnedObjectives = pinnedObjectives.filter((x) => !isComplete(x));
    if (newPinnedObjectives.length !== pinnedObjectives.length) {
      setPinnedObjectives(newPinnedObjectives);
    }
  }, [game.claimedTracks, setPinnedObjectives, pinnedObjectives, isComplete]);

  const [drawingObjectives, setDrawingObjectives] = useState(false);

  async function drawObjectives() {
    setDrawingObjectives(true);
    try {
      const gameData = await apiPost(`game/${game.id}/draw-objectives`, {
        playerCode: player.code,
      });
      setGameData(gameData.game);
      setPlayerData(gameData.player);
    } catch (error) {}
    setDrawingObjectives(false);
  }

  const completedObjectives = objectives.reduce(
    (acc, x) => acc + +isComplete(x),
    0
  );

  function incompleteObjectiveIcon(objective: ObjectiveData) {
    const { cityA, cityB } = objective;
    const isPinned = pinnedObjectives.find(
      (x) => cityA === x.cityA && cityB === x.cityB
    );
    return (
      <Tooltip label={isPinned ? 'Unpin objective' : 'Pin objective'}>
        <UnstyledButton
          className="flex items-center justify-center"
          onClick={() => {
            if (isPinned) {
              setPinnedObjectives(
                pinnedObjectives.filter(
                  (x) => cityA !== x.cityA || cityB !== x.cityB
                )
              );
            } else {
              setPinnedObjectives([...pinnedObjectives, objective]);
            }
          }}
        >
          {isPinned ? <FaThumbtack /> : <FaDotCircle />}
        </UnstyledButton>
      </Tooltip>
    );
  }

  return (
    <>
      {objectives.length > 0 && (
        <>
          <Flex
            className="mb-2 max-w-xl"
            justify="space-between"
            align="center"
          >
            <Text size="lg" weight="bold">
              Objectives:
            </Text>
            <Button
              onClick={drawObjectives}
              className="float-right"
              color="yellow"
              loaderPosition="center"
              loading={drawingObjectives}
              disabled={game.isOver}
            >
              <FaPlus />
            </Button>
          </Flex>
          {completedObjectives > 0 && (
            <Checkbox
              label={`Hide ${completedObjectives} completed objective(s)`}
              className="mb-2"
              checked={hideCompleted}
              onChange={(ev) => setHideCompleted(ev.target.checked)}
            />
          )}
          <ScrollArea className="h-80 max-w-xl" type="auto">
            {objectives
              .filter((x) => !hideCompleted || !isComplete(x))
              .map((objective, idx) => (
                <div
                  className="mb-2"
                  key={idx}
                  onMouseEnter={() => setHoveredObjective(objective)}
                  onMouseLeave={() => setHoveredObjective(null)}
                >
                  <Objective
                    objective={objective}
                    key={idx}
                    complete={isComplete(objective)}
                  >
                    {isComplete(objective) ? (
                      <FaCheck />
                    ) : (
                      incompleteObjectiveIcon(objective)
                    )}
                  </Objective>
                </div>
              ))}
          </ScrollArea>
        </>
      )}
      {pendingObjectives.length > 0 && (
        <PendingObjectives
          setGameData={setGameData}
          setPlayerData={setPlayerData}
          game={game}
          player={player}
          isComplete={isComplete}
          setHoveredObjective={setHoveredObjective}
        />
      )}
    </>
  );
}
