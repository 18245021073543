import { Box, Flex, Text, Tooltip } from '@mantine/core';
import React from 'react';
import {
  FaBuilding,
  FaHandPaper,
  FaLocationArrow,
  FaStar,
  FaTrain,
} from 'react-icons/fa';
import { PublicPlayerData } from '../../types/game';

interface Props {
  player: PublicPlayerData;
  connected: boolean;
  isCurrentPlayer: boolean;
}

export default function PlayerCard({
  player,
  connected,
  isCurrentPlayer,
}: Props) {
  return (
    <div
      className={`${isCurrentPlayer ? 'border-4' : 'border-2'}
      rounded-md p-2 player-card shadow-md
      player-card-${player.color}
      ${connected ? 'player-connected' : 'player-disconnected'}`}
    >
      <Flex justify="space-between">
        <Text weight="bold">{player.name}</Text>
        <Tooltip label="Score">
          <Box>
            <FaStar className="mr-1" />
            <Text span>{player.score}</Text>
          </Box>
        </Tooltip>
        <Text size="xs" className={connected ? 'player-status-connected' : ''}>
          {connected ? 'online' : 'offline'}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Tooltip label="Remaining trains">
          <Box>
            <FaTrain className="mr-1" />
            <Text span>{player.trains}</Text>
          </Box>
        </Tooltip>
        <Tooltip label="Objectives">
          <Box>
            <FaLocationArrow className="mr-1" />
            <Text span>{player.objectivesCount}</Text>
          </Box>
        </Tooltip>
        <Tooltip label="Cards in hand">
          <Box>
            <FaHandPaper className="mr-1" />
            <Text span>{player.cardsCount}</Text>
          </Box>
        </Tooltip>
        <Tooltip label="Remaining stations">
          <Box>
            <FaBuilding className="mr-1" />
            <Text span>{player.stations}</Text>
          </Box>
        </Tooltip>
      </Flex>
    </div>
  );
}
