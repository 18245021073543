import { BackgroundImage, Card, Center, Title } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';
import backgroundImage from '../../assets/background.jpg';

export default function RoomJoinerShell() {
  return (
    <BackgroundImage src={backgroundImage}>
      <Center className="min-h-screen w-screen">
        <Card
          shadow="lg"
          p="lg"
          radius="md"
          withBorder
          className="w-full max-w-md bg-white bg-opacity-80 backdrop-blur-sm border-white border-opacity-80"
        >
          <Title className="mb-4" align="center">
            Admission to Cruise!
          </Title>
          <Card.Section>
            <div className="border-b mb-4 border-white"></div>
          </Card.Section>
          <Outlet></Outlet>
        </Card>
      </Center>
    </BackgroundImage>
  );
}
