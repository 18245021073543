import React, { useEffect, useState } from 'react';
import {
  BackgroundImage,
  Box,
  Button,
  FileButton,
  Flex,
  NumberInput,
  ScrollArea,
  Select,
  Text,
  UnstyledButton,
} from '@mantine/core';
import TrainSegment from '../map/TrainSegment';
import TrainPlacer from './TrainPlacer';
import { FaCheck, FaPlus, FaSave, FaTimes, FaUpload } from 'react-icons/fa';
import CityPlacer from './CityPlacer';
import MapCity from '../map/MapCity';
import { saveAs } from 'file-saver';
import TrainTrack from '../map/TrainTrack';
import { CityData, TrackColor, TrackData, trainColors } from '../../types/game';

export default function MapEditor() {
  // segments not yet used in a track
  const [segments, setSegments] = useState<[number, number, number][]>([]);
  // completed tracks
  const [tracks, setTracks] = useState<TrackData[]>([]);
  // placed cities
  const [cities, setCities] = useState<CityData[]>([]);
  const [tool, setTool] = useState<
    'train-placer' | 'city-placer' | 'track-placer' | null
  >(null);
  // uploaded import file
  const [file, setFile] = useState<File | null>(null);
  // used for track placer
  const [startCity, setStartCity] = useState<CityData | null>(null);
  const [requiredLocomotives, setRequiredLocomotives] = useState(1);
  const [selectedSegments, setSelectedSegments] = useState<
    [number, number, number][]
  >([]);
  const [selectedColor, setSelectedColor] = useState<TrackColor>('gray');

  const [nextId, setNextId] = useState(1);
  useEffect(() => {
    // load the data from local storage on init
    const newSegments = JSON.parse(
      localStorage.getItem('train-segments') ?? '[]'
    );
    const newCities: CityData[] = JSON.parse(
      localStorage.getItem('cities') ?? '[]'
    );
    let newTracks: TrackData[] = JSON.parse(
      localStorage.getItem('tracks') ?? '[]'
    );
    setSegments([...newSegments]);
    setCities([...newCities]);
    setTracks([...newTracks]);
    const firstAvailableId =
      newTracks.reduce((acc, x) => Math.max(acc, x.id), 0) + 1;
    setNextId(firstAvailableId);
  }, []);

  // update functions, updates local storage as well
  function updateSegments(segments: [number, number, number][]) {
    setSegments(segments);
    localStorage.setItem('train-segments', JSON.stringify(segments));
  }

  function updateTracks(tracks: TrackData[]) {
    setTracks(tracks);
    localStorage.setItem('tracks', JSON.stringify(tracks));
  }

  function updateCities(cities: CityData[]) {
    setCities(cities);
    localStorage.setItem('cities', JSON.stringify(cities));
  }

  // save and upload
  function saveMap() {
    const blob = new Blob([JSON.stringify({ segments, cities, tracks })]);
    saveAs(blob, 'ttr_data.json');
  }

  function handleConfirmInput() {
    file!.text().then((data) => {
      // note: input is not validated
      const res = JSON.parse(data);
      updateSegments(res.segments);
      updateCities(res.cities);
      updateTracks(res.tracks);
      setFile(null);
    });
  }

  // used for tracks
  function selectCity(city: CityData) {
    if (startCity?.name === city.name) {
      setStartCity(null);
    } else if (startCity === null) {
      setStartCity(city);
    } else if (selectedSegments.length > 0) {
      // create a new track
      updateTracks([
        ...tracks,
        {
          color: selectedColor,
          cityA: startCity.name,
          cityB: city.name,
          segments: selectedSegments,
          requiredLocomotives:
            selectedColor === 'rainbow'
              ? Math.min(selectedSegments.length, requiredLocomotives)
              : 0,
          id: nextId,
        },
      ]);
      setNextId(nextId + 1);
      updateSegments(
        segments.filter((x) => selectedSegments.indexOf(x) === -1)
      );
      setSelectedSegments([]);
      setStartCity(null);
    }
  }

  function selectSegment(segment: [number, number, number]) {
    const cityIdx = selectedSegments.findIndex((x) => x === segment);
    if (cityIdx !== -1) {
      setSelectedSegments(selectedSegments.filter((x) => x !== segment));
    } else {
      setSelectedSegments([...selectedSegments, segment]);
    }
  }

  function deleteTrack(track: TrackData) {
    updateTracks(tracks.filter((x) => x !== track));
    updateSegments([...segments, ...track.segments]);
  }

  return (
    <Flex>
      <Flex className="border p-2 h-fit m-1" gap="sm" direction="column">
        <Text size="lg" weight="bold">
          Tools
        </Text>
        <Select
          value={tool}
          onChange={(value) => setTool(value as any)}
          data={[
            { value: 'train-placer', label: 'Train placer' },
            { value: 'city-placer', label: 'City placer' },
            { value: 'track-placer', label: 'Track placer' },
          ]}
          placeholder="Select a tool..."
        />
        {tool === 'track-placer' && (
          <Select
            label="Track color"
            value={selectedColor}
            onChange={(color: any) => setSelectedColor(color)}
            data={trainColors.map((color) => ({
              value: color,
              label: color.charAt(0).toUpperCase() + color.slice(1),
            }))}
            placeholder="Color for the track..."
          />
        )}
        {tool === 'track-placer' && selectedColor === 'rainbow' && (
          <NumberInput
            label="Required locomotives"
            value={requiredLocomotives}
            min={1}
            onChange={(value) => setRequiredLocomotives(value ?? 1)}
          ></NumberInput>
        )}
        <Button rightIcon={<FaSave></FaSave>} onClick={saveMap}>
          Save
        </Button>
        <Flex gap="sm">
          {file === null && (
            <FileButton onChange={setFile} accept="application/json">
              {(props) => (
                <Button
                  {...props}
                  color="violet"
                  className="flex-grow"
                  leftIcon={<FaUpload></FaUpload>}
                >
                  Upload data
                </Button>
              )}
            </FileButton>
          )}
          {file !== null && (
            <>
              <Button
                color="green"
                leftIcon={<FaCheck></FaCheck>}
                onClick={handleConfirmInput}
              >
                Confirm import
              </Button>
              <Button color="red" onClick={() => setFile(null)}>
                <FaTimes></FaTimes>
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <ScrollArea className="w-full h-full">
        <Box h="780px" w="1200px" className="relative">
          {/* segments */}
          {segments.map((value, idx) => (
            <TrainSegment
              className={tool === 'train-placer' ? '' : 'opacity-60'}
              color={tool === 'train-placer' ? 'green' : 'gray'}
              key={idx}
              left={value[0]}
              top={value[1]}
              rot={value[2]}
            >
              {tool === 'train-placer' && (
                <UnstyledButton
                  onClick={() =>
                    updateSegments(
                      segments.filter((segment) => segment !== value)
                    )
                  }
                >
                  <FaTimes></FaTimes>
                </UnstyledButton>
              )}
              {tool === 'track-placer' && (
                <UnstyledButton onClick={() => selectSegment(value)}>
                  {selectedSegments.find((x) => x === value) ? (
                    <FaTimes className="text-sm text-red-700"></FaTimes>
                  ) : (
                    <FaPlus className="text-sm"></FaPlus>
                  )}
                </UnstyledButton>
              )}
            </TrainSegment>
          ))}
          {/* cities */}
          {cities.map((value, idx) => (
            <MapCity key={idx} {...value}>
              {tool === 'city-placer' && (
                <UnstyledButton>
                  <FaTimes
                    className="text-sm mb-1"
                    onClick={() =>
                      updateCities(
                        cities.filter((city) => city.name !== value.name)
                      )
                    }
                  ></FaTimes>
                </UnstyledButton>
              )}
              {tool === 'track-placer' && (
                // allows to select a city, the second city is shown with a checkmark
                <UnstyledButton onClick={() => selectCity(value)}>
                  {startCity?.name === value.name ? (
                    <FaTimes className="text-sm mb-1 text-red-700"></FaTimes>
                  ) : startCity ? (
                    <FaCheck className="text-sm mb-1"></FaCheck>
                  ) : (
                    <FaPlus className="text-sm mb-1"></FaPlus>
                  )}
                </UnstyledButton>
              )}
            </MapCity>
          ))}
          {/* tracks */}
          {tracks.map((track, trackIdx) => (
            <TrainTrack {...track} key={trackIdx}>
              {tool === 'track-placer' && (
                <UnstyledButton onClick={() => deleteTrack(track)}>
                  <FaTimes></FaTimes>
                </UnstyledButton>
              )}
            </TrainTrack>
          ))}
          {/* tools */}
          {tool === 'train-placer' && (
            <TrainPlacer
              placeTrain={(left, top, rot) =>
                updateSegments([...segments, [left, top, rot]])
              }
            ></TrainPlacer>
          )}
          {tool === 'city-placer' && (
            <CityPlacer
              placeCity={(city) => updateCities([...cities, city])}
            ></CityPlacer>
          )}
          <BackgroundImage src={'assets/map.jpg'}>
            <Box h="780px" w="1200px"></Box>
          </BackgroundImage>
        </Box>
      </ScrollArea>
    </Flex>
  );
}
