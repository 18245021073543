import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, Flex, TextInput, Text, ScrollArea } from '@mantine/core';
import { FaPaperPlane } from 'react-icons/fa';
import ChatMessage from './ChatMessage';
import { ChatMessageData } from '../../types/game';
import { Socket } from 'socket.io-client';
import { socketEmit, socketHandle } from '../../services/socket-service';

type Props = {
  socket: Socket;
  isConnected: boolean;
};

export default function Chat({ socket, isConnected: connected }: Props) {
  const viewport = useRef<HTMLDivElement>(null);
  const [textBox, setTextBox] = useState('');
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });
  const [scrolling, setScrolling] = useState(false);
  const [chat, setChat] = useState<ChatMessageData[]>([
    {
      player: null,
      text: 'Welcome to the game room!',
      event: { type: 'system' },
    },
  ]);

  useEffect(() => {
    socketHandle('chat-message', socket, ({ message }) => {
      setChat([...chat, message]);
      setScrolling(true);
    });
    return () => {
      socket.off('chat-message');
    };
  }, [socket, chat]);
  useEffect(() => {
    if (!scrolling) {
      return;
    }
    setScrolling(false);
    if (!viewport.current) {
      return;
    }
    const diff = Math.abs(viewport.current.scrollHeight - scrollPosition.y);
    const scrollAmount = diff - viewport.current.clientHeight;
    if (scrollAmount > 200) {
      // don't scroll so far
      return;
    }
    viewport.current?.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [scrolling, scrollPosition.y]);

  function sendMessage(ev: FormEvent) {
    ev.preventDefault();
    if (!textBox) {
      return;
    }
    setTextBox('');
    socketEmit('send-message', socket, { text: textBox });
  }

  return (
    <Flex direction="column" className="pl-2 pt-2 pb-2 items-stretch h-full">
      <Text size="lg" weight="bold">
        Chat
      </Text>
      <ScrollArea
        className="flex-grow"
        classNames={{ viewport: 'no-display-table' }}
        offsetScrollbars
        viewportRef={viewport}
        onScrollPositionChange={onScrollPositionChange}
      >
        <div>
          {chat.map((message, idx) => (
            <ChatMessage message={message} key={idx}></ChatMessage>
          ))}
        </div>
      </ScrollArea>
      <form onSubmit={sendMessage}>
        {!connected && (
          <Text size="xs" color="gray">
            I'm trying to connect you to the game...
          </Text>
        )}
        <Flex gap="xs" className="pt-2 pr-2">
          <TextInput
            className="flex-grow"
            disabled={!connected}
            type="text"
            placeholder="Your message here..."
            value={textBox}
            onChange={(ev) => setTextBox(ev.target.value)}
            maxLength={128}
            autoComplete="off"
          ></TextInput>
          <Button type="submit" disabled={!connected}>
            <FaPaperPlane></FaPaperPlane>
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}
