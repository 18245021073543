import {
  Button,
  ColorSwatch,
  Flex,
  FocusTrap,
  Group,
  Input,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { FormEvent, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiPost } from '../services/api-service';
import { invalidFormNotification } from '../services/forms-service';
import { PlayerColor, playerColors, PlayerGameData } from '../types/game';
import CopyRoomCodeButton from './CopyRoomCodeButton';

export default function RoomJoiner() {
  const [username, setUsername] = useState('');
  const [selectedColor, setSelectedColor] = useState<PlayerColor | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const { search } = useLocation();
  const [gameId, setGameId] = useState<string | null>(() => {
    const parameters = new URLSearchParams(search);
    return parameters.get('join');
  });
  const [playerCode, setPlayerCode] = useState<string | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function createGame() {
    return apiPost<PlayerGameData>('game', {
      username,
      color: selectedColor,
    });
  }

  function joinGame() {
    return apiPost<PlayerGameData>(`game/${gameId}`, {
      username,
      color: selectedColor,
    });
  }

  async function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    setSubmitted(true);
    if (!username || !selectedColor) {
      invalidFormNotification();
      return;
    }
    setLoading(true);
    let res: PlayerGameData;
    try {
      res = await (gameId === null ? createGame() : joinGame());
    } catch (error) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setGameId(res.game.id);
    setPlayerCode(res.player.code);
  }

  const theme = useMantineTheme();
  const colorsMap: { [color in PlayerColor]: string } = {
    red: 'red',
    green: 'green',
    blue: 'blue',
    yellow: 'yellow',
    black: 'dark',
  };

  if (gameId && playerCode) {
    return (
      <Flex gap="md" direction="column">
        <Text size="xl">Your game room is ready!</Text>
        <Text size="sm">Share this url with your friends to play:</Text>
        <CopyRoomCodeButton gameId={gameId} autoFocus={true} />
        <Button
          onClick={() => navigate(`/game/${gameId}?player=${playerCode}`)}
        >
          Go to game room
        </Button>
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex gap="md" direction="column">
        <FocusTrap>
          <TextInput
            label="Username"
            placeholder="John Doe"
            maxLength={40}
            required
            onChange={(ev) => setUsername(ev.target.value)}
            value={username}
          />
        </FocusTrap>

        <Input.Wrapper required label="Your color">
          <Group position="center" spacing="xs">
            {playerColors.map((color) => (
              <Tooltip label={color} key={color}>
                <ColorSwatch
                  component="button"
                  color={theme.colors[colorsMap[color]][6]}
                  onClick={() => setSelectedColor(color)}
                  sx={{ color: '#fff', cursor: 'pointer' }}
                  type="button"
                >
                  {selectedColor === color && <FaCheck />}
                </ColorSwatch>
              </Tooltip>
            ))}
          </Group>
          {submitted && !selectedColor && (
            <Input.Error>Please select a color!</Input.Error>
          )}
        </Input.Wrapper>

        <Button type="submit" loading={loading}>
          {gameId === null ? 'Create game!' : 'Join game!'}
        </Button>
      </Flex>
    </form>
  );
}
