import { Socket } from 'socket.io-client';
import {
  ClientSocketMessageTypes,
  ServerSocketMessageTypes,
} from '../types/socket';

export function socketEmit<Key extends keyof ClientSocketMessageTypes>(
  type: Key,
  socket: Socket,
  data: ClientSocketMessageTypes[Key]
) {
  socket.emit(type, data);
}

export function socketHandle<Key extends keyof ServerSocketMessageTypes>(
  type: Key,
  socket: Socket,
  listener: (data: ServerSocketMessageTypes[Key]) => void
) {
  socket.on(type as string, (data) => {
    listener(data);
  });
}
