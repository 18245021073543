import { Flex, LoadingOverlay, SimpleGrid, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaInfo, FaTimes } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import {
  CardColor,
  PlayerData,
  PlayerGameData,
  PublicGameData,
} from '../../types/game';

interface Props {
  game: PublicGameData;
  currentPlayer: PlayerData;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function Decks({
  game,
  currentPlayer,
  setGameData,
  setPlayerData,
}: Props) {
  const [pickingTrainCard, setPickingTrainCard] = useState(false);
  const [trainCardButton, setTrainCardButton] = useState<string | null>(null);
  const [pickedCardsCount, setPickedCardsCount] = useState<number[]>([]);
  const { shownTrainCards } = game;
  useEffect(() => {
    if (pickedCardsCount.length !== shownTrainCards.length) {
      setPickedCardsCount(shownTrainCards.map(() => 0));
    }
  }, [pickedCardsCount, shownTrainCards]);

  async function pickTrainCards(
    deckCards: number | null = 1,
    shownCardIdx: number | null = null
  ) {
    setPickingTrainCard(true);
    try {
      const { lastPicks, ...data } = await apiPost<
        { lastPicks: CardColor[] } & PlayerGameData
      >(`game/${game.id}/pick-trains`, {
        playerCode: currentPlayer.code,
        shownCardIdx,
        deckCards,
      });
      setGameData(data.game);
      setPlayerData(data.player);
      setPickedCardsCount(
        pickedCardsCount.map((value, idx) =>
          idx === shownCardIdx ? value + 1 : value
        )
      );
      showNotification({
        message: `You picked ${lastPicks
          .map((card) => `${card} train`)
          .join(' and ')}`,
        icon: <FaInfo />,
      });
    } catch (error) {}
    setPickingTrainCard(false);
    setTrainCardButton(null);
  }

  const deckCardsShown =
    game.trainsDeckSize >= 50
      ? 3
      : game.trainsDeckSize >= 20
      ? 2
      : game.trainsDeckSize >= 10
      ? 1
      : 0;

  return (
    <SimpleGrid
      className="h-full items-center p-3 max-h-40"
      cols={shownTrainCards.length + 1}
    >
      <Flex
        className={`border border-yellow-400 rounded-xl h-full flex-grow
          p-3 deck-cards-${deckCardsShown}
          ${game.trainsDeckSize > 0 && 'bg-yellow-300'}`}
        direction="column"
      >
        <button
          onClick={() => {
            setTrainCardButton('btn-1');
            pickTrainCards(1);
          }}
          disabled={pickingTrainCard}
          className="overflow-hidden relative flex-grow h-full text-3xl justify-center font-bold flex items-center
          border rounded-t-lg border-yellow-400 cursor-pointer bg-yellow-200 hover:bg-yellow-100"
        >
          <LoadingOverlay
            visible={trainCardButton === `btn-1`}
            overlayBlur={2}
          />
          1 <FaTimes />
        </button>
        <button
          onClick={() => {
            setTrainCardButton('btn-2');
            pickTrainCards(2);
          }}
          disabled={pickingTrainCard}
          className="overflow-hidden relative flex-grow h-full text-3xl justify-center font-bold flex items-center
            border-r border-l border-b rounded-b-lg border-yellow-400 cursor-pointer bg-yellow-200 hover:bg-yellow-100"
        >
          <LoadingOverlay
            visible={trainCardButton === `btn-2`}
            overlayBlur={2}
          />
          2 <FaTimes />
        </button>
      </Flex>
      {shownTrainCards.map((card, idx) => (
        <button
          key={`${idx}-${card}-${pickedCardsCount[idx]}`}
          className={`relative border hover:border-2 flex-grow h-full shown-card-${card} rounded-lg text-center
              cursor-pointer shadow-md overflow-hidden`}
          style={{ animation: 'card-appear-animation 300ms' }}
          disabled={pickingTrainCard}
          onClick={() => {
            pickTrainCards(null, idx);
            setTrainCardButton(`shown-card-${idx}`);
          }}
        >
          <LoadingOverlay
            visible={trainCardButton === `shown-card-${idx}`}
            overlayBlur={2}
          />
          <Text className="capitalize" size="lg" weight="bold">
            {card} train
          </Text>
        </button>
      ))}
    </SimpleGrid>
  );
}
