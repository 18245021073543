import { showNotification } from '@mantine/notifications';

interface ApiResult {
  status: 'success';
  data: any;
}

interface ApiError {
  status: 'fail' | 'error';
  message: string;
}

type ApiResponse = ApiResult | ApiError;

export class ApiException extends Error {
  constructor(response: ApiError) {
    super(response.message);
  }
}

async function apiCall(url: string, method = 'get', data?: any) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    // prefix the url with the base api url
    url = `${process.env.REACT_APP_API_URL}/api/${url}`;
  }

  let res: ApiResponse;
  try {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
    const body = data ? JSON.stringify(data) : undefined;
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    res = await response.json();
  } catch (error) {
    // generic error
    res = {
      status: 'error',
      message: 'Connection error',
    };
  }

  if (res.status !== 'success') {
    // internal server error or call error
    showNotification({
      title:
        res.status === 'error' ? 'There was an error' : 'Something is wrong',
      message: res.message,
      color: 'red',
    });
    throw new ApiException(res);
  }

  return res.data;
}

export function apiPost<T = any>(url: string, data: object): Promise<T> {
  return apiCall(url, 'post', data);
}

export function apiGet<T = any>(url: string): Promise<T> {
  return apiCall(url, 'get');
}

export function apiDelete<T = any>(url: string): Promise<T> {
  return apiCall(url, 'delete');
}

export function apiPut<T = any>(url: string, data: object): Promise<T> {
  return apiCall(url, 'put', data);
}
