import { useMouse } from '@mantine/hooks';
import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from 'react';

interface Props {
  placeTrain?: (left: number, top: number, rot: number) => void;
}

export default function TrainPlacer({ placeTrain }: Props) {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [rot, setRot] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [rotating, setRotating] = useState(false);
  const { x, y } = useMouse();
  const [lastX, setLastX] = useState(x);
  const [lastY, setLastY] = useState(y);

  function startDragging() {
    setDragging(true);
    setLastX(x);
    setLastY(y);
  }
  function startRotating() {
    setRotating(true);
    setLastX(x);
    setLastY(y);
  }

  function handleMouseDown(event: ReactMouseEvent) {
    setRotating(false);
    setDragging(false);
    if (event.button === 0) {
      startDragging();
      setRotating(false);
    } else if (event.button === 2) {
      startRotating();
      setDragging(false);
    }
  }

  useEffect(() => {
    const enterListener = (ev: KeyboardEvent) => {
      if (ev.key === 'Enter' && placeTrain) {
        placeTrain(left, top, rot);
      }
    };
    window.addEventListener('keypress', enterListener);
    return () => {
      window.removeEventListener('keypress', enterListener);
    };
  });
  useEffect(() => {
    // listen to right clicks
    const mouseListener = (ev: MouseEvent) => {
      if (ev.button === 0) {
        setDragging(false);
      } else if (ev.button === 2) {
        setRotating(false);
      }
    };
    window.addEventListener('mouseup', mouseListener);
    return () => {
      window.removeEventListener('mouseup', mouseListener);
    };
  });

  if (dragging && Math.abs(lastX - x) + Math.abs(lastY - y) > 0) {
    setTop(top + y - lastY);
    setLeft(left + x - lastX);
    setLastX(x);
    setLastY(y);
  } else if (rotating && Math.abs(lastY - y)) {
    setRot(rot + y - lastY);
    setLastY(y);
  }

  return (
    <div
      className="bg-green-400 border border-green-900 text-xs opacity-70"
      style={{
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        transform: `rotate(${rot}deg)`,
        width: '44px',
        height: '16px',
      }}
      onMouseDown={(event) => handleMouseDown(event)}
      onContextMenu={(event) => event.preventDefault()}
    ></div>
  );
}
