import { SimpleGrid, Text } from '@mantine/core';
import React from 'react';
import { CardColor, cardColors } from '../../types/game';

interface Props {
  cards: CardColor[];
}

export default function PlayerCards({ cards }: Props) {
  const cardsByType: { [color: string]: number } = {};
  cards.forEach((card) => {
    cardsByType[card] ??= 0;
    cardsByType[card]! += 1;
  });

  return (
    <div className="pb-3">
      <Text size="lg" weight="bold">
        Your Cards:
      </Text>
      <SimpleGrid cols={5} className="max-w-md">
        {cardColors
          .filter((card) => cardsByType[card])
          .map((card) => (
            <div
              key={card}
              className={`border shown-card-${card} p-3 rounded-lg flex justify-center items-center`}
            >
              {cardsByType[card]}
            </div>
          ))}
      </SimpleGrid>
    </div>
  );
}
