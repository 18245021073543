import { Button, Flex, Group, Modal, Space, Text } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FaExclamationTriangle, FaRedo } from 'react-icons/fa';
import { Socket } from 'socket.io-client';
import CopyRoomCodeButton from '../../room-joiner/CopyRoomCodeButton';
import { apiGet, apiPost } from '../../services/api-service';
import { socketHandle } from '../../services/socket-service';
import {
  PlayerColor,
  playerColors,
  PlayerData,
  PlayerGameData,
  PublicGameData,
} from '../../types/game';
import PlayerCard from './PlayerCard';

interface Props {
  socket: Socket;
  game: PublicGameData;
  currentPlayer: PlayerData;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function Players({
  socket,
  game,
  currentPlayer,
  setGameData,
  setPlayerData,
}: Props) {
  const [connectedPlayers, setConnectedPlayers] = useState<PlayerColor[]>([]);
  const [restartGameOpened, setRestartGameOpened] = useState(false);
  const [restartingGame, setRestartingGame] = useState(false);
  const { players } = game;
  const loadConnectedPlayers = useCallback(async () => {
    try {
      const res = await apiGet<{ color: PlayerColor; name: string }[]>(
        `game/${game.id}/online`
      );
      setConnectedPlayers(res.map((x) => x.color));
    } catch (error) {}
    return [];
  }, [game.id]);

  useEffect(() => {
    loadConnectedPlayers();
  }, [loadConnectedPlayers]);

  useEffect(() => {
    socketHandle('user-connected', socket, ({ color }) => {
      if (color === currentPlayer.color) {
        // reload the list
        loadConnectedPlayers();
        return;
      }
      setConnectedPlayers([...connectedPlayers, color]);
    });
    socketHandle('user-disconnected', socket, ({ color }) => {
      setConnectedPlayers(connectedPlayers.filter((x) => x !== color));
    });

    return () => {
      socket.off('user-connected');
      socket.off('user-disconnected');
    };
  }, [socket, currentPlayer.color, connectedPlayers, loadConnectedPlayers]);

  async function restartGame() {
    setRestartingGame(true);
    try {
      const data = await apiPost<PlayerGameData>(`game/${game.id}/restart`, {
        playerCode: currentPlayer.code,
      });
      setGameData(data.game);
      setPlayerData(data.player);
      setRestartGameOpened(false);
    } catch (error) {}
    setRestartingGame(false);
  }

  return (
    <Flex direction="column" className="p-2 h-full justify-between">
      {players.map((player) => (
        <PlayerCard
          isCurrentPlayer={player.color === currentPlayer.color}
          player={player}
          key={player.color}
          connected={connectedPlayers.includes(player.color)}
        ></PlayerCard>
      ))}
      {players.length < playerColors.length && !game.isOver && (
        <CopyRoomCodeButton gameId={game.id} label="Invite your friends!" />
      )}
      <Modal
        centered
        opened={restartGameOpened}
        onClose={() => setRestartGameOpened(false)}
        title={
          <Text weight="bold" size="lg">
            Reset the game room?
          </Text>
        }
      >
        <Text size="md">
          <FaExclamationTriangle /> Game results will be lost.
        </Text>
        <Space h="md" />
        <Group position="center">
          <Button
            color="red"
            leftIcon={<FaRedo />}
            onClick={restartGame}
            loading={restartingGame}
          >
            Confirm
          </Button>
          <Button onClick={() => setRestartGameOpened(false)} color="gray">
            Cancel
          </Button>
        </Group>
      </Modal>

      {game.isOver && (
        <Button onClick={() => setRestartGameOpened(true)}>Play again</Button>
      )}
    </Flex>
  );
}
