import React, { useEffect, useState } from 'react';
import { BackgroundImage, Box, Loader, UnstyledButton } from '@mantine/core';
import MapCity from '../map/MapCity';
import TrainTrack from '../map/TrainTrack';
import {
  CityData,
  GameOverDataObjective,
  ObjectiveData,
  PlayerData,
  PlayerGameData,
  PublicGameData,
  TrackData,
} from '../../types/game';
import mapImage from '../../../assets/map.jpg';
import { FaCheck } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import { showNotification } from '@mantine/notifications';
import { useDebouncedState } from '@mantine/hooks';

interface Props {
  hoveredObjective: ObjectiveData | null;
  selectedTrack: TrackData | null;
  setSelectedTrack: (track: TrackData | null) => void;
  selectedCity: CityData | null;
  setSelectedCity: (city: CityData | null) => void;
  game: PublicGameData;
  player: PlayerData;
  pinnedObjectives: ObjectiveData[];
  revealedObjective: GameOverDataObjective | null;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export interface TrackAnimation {
  type: 'track';
  id: number;
  cityA: string;
  cityB: string;
  delay: number;
}

export default function GameMap({
  hoveredObjective,
  selectedTrack,
  setSelectedTrack,
  selectedCity,
  setSelectedCity,
  game,
  player,
  pinnedObjectives,
  revealedObjective,
  setGameData,
  setPlayerData,
}: Props) {
  const [settingTargetStation, setSettingTargetStation] =
    useState<CityData | null>(null);
  const [animatedPath, setAnimatedPath] = useState<{
    objective: GameOverDataObjective;
    animation: TrackAnimation[];
  } | null>(null);

  /**
   * calculates the animation for the track
   */
  useEffect(() => {
    if (!revealedObjective || !revealedObjective.path) {
      // resets the animated path if present
      if (animatedPath) {
        setAnimatedPath(null);
      }
      return;
    } else if (
      animatedPath &&
      animatedPath.objective.cityA === revealedObjective.cityA &&
      animatedPath.objective.cityB === revealedObjective.cityB
    ) {
      // no need to update it
      return;
    }
    // calculate the delays
    let animationDelay = 0;
    const animation: TrackAnimation[] = [];
    revealedObjective.path.forEach((section) => {
      const track = game.tracks.find((x) => x.id === section.id);
      if (!track) {
        return;
      }
      animation.push({
        ...section,
        delay: animationDelay,
      });
      animationDelay += track.segments.length;
    });
    setAnimatedPath({ objective: revealedObjective, animation });
  }, [revealedObjective, animatedPath, game.tracks]);

  useEffect(() => {
    if (!game.isOver) {
      return;
    }
    if (selectedTrack !== null) {
      setSelectedTrack(null);
    }
    if (selectedCity !== null) {
      const station = game.stations.find((x) => x.city === selectedCity.name);
      if (station?.player !== player.color) {
        setSelectedCity(null);
      }
    }
  }, [
    game.isOver,
    selectedTrack,
    setSelectedTrack,
    selectedCity,
    setSelectedCity,
    game.stations,
    player.color,
  ]);

  const objectiveCities: { [key: string]: true } = {};
  player.objectives.forEach((objective) => {
    objectiveCities[objective.cityA] = true;
    objectiveCities[objective.cityB] = true;
  });
  const [hoveredTrack, setHoveredTrack] = useDebouncedState<TrackData | null>(
    null,
    200
  );

  function isObjective(city: CityData) {
    if (hoveredObjective) {
      const { cityA, cityB } = hoveredObjective;
      return [cityA, cityB].includes(city.name);
    }
    return objectiveCities[city.name] ?? false;
  }

  function showCityName(city: CityData) {
    if (revealedObjective) {
      // highest priority to the revealed objective
      const { cityA, cityB } = revealedObjective;
      return [cityA, cityB].includes(city.name);
    }
    if (hoveredObjective) {
      // hovered objective takes priority
      const { cityA, cityB } = hoveredObjective;
      return [cityA, cityB].includes(city.name);
    }
    if (
      pinnedObjectives.find(
        (x) => x.cityA === city.name || x.cityB === city.name
      )
    ) {
      return true;
    }
    if (hoveredTrack) {
      const { cityA, cityB } = hoveredTrack;
      if ([cityA, cityB].includes(city.name)) {
        return true;
      }
    }
    if (selectedTrack) {
      const { cityA, cityB } = selectedTrack;
      if ([cityA, cityB].includes(city.name)) {
        return true;
      }
    }
    return selectedCity?.name === city.name;
  }

  function findClaimedTrack(track: TrackData) {
    return game.claimedTracks.find((x) => x.id === track.id);
  }

  function isClaimable(track: TrackData) {
    return !findClaimedTrack(track) && !game.isOver;
  }

  function handleTrackClick(track: TrackData) {
    if (isClaimable(track)) {
      if (selectedTrack?.id === track.id) {
        setSelectedTrack(null);
        setHoveredTrack(null);
        return;
      }
      setSelectedTrack(track);
      setSelectedCity(null);
    }
  }

  function findStation(city: CityData) {
    return game.stations.find((x) => x.city === city.name);
  }

  function isCityClickable(city: CityData) {
    const station = findStation(city);
    if (station === undefined) {
      return !game.isOver;
    }
    return station.player === player.color;
  }

  function handleCityClick(city: CityData) {
    if (isCityClickable(city)) {
      if (selectedCity?.name === city.name) {
        setSelectedCity(null);
        return;
      }
      setSelectedCity(city);
      setSelectedTrack(null);
    }
  }

  function adjacentStationSelected(city: CityData) {
    if (!selectedCity || !findStation(selectedCity)) {
      return false;
    }
    return (
      game.claimedTracks.find(
        (x) =>
          x.player !== player.color &&
          ((x.cityA === city.name && x.cityB === selectedCity.name) ||
            (x.cityA === selectedCity.name && x.cityB === city.name))
      ) !== undefined
    );
  }

  async function setStationTarget(city: CityData) {
    if (!selectedCity) {
      return;
    }
    setSettingTargetStation(city);
    try {
      const gameData = await apiPost<PlayerGameData>(
        `/game/${game.id}/set-station-target`,
        {
          playerCode: player.code,
          city: selectedCity.name,
          toCity: city.name,
        }
      );
      setGameData(gameData.game);
      setPlayerData(gameData.player);
      showNotification({
        message: `Set destination to ${city.name}`,
      });
    } catch (error) {}
    setSettingTargetStation(null);
  }

  function stationTargetSelectButton(city: CityData) {
    if (settingTargetStation?.name === city.name) {
      return <Loader radius={4} color="dark"></Loader>;
    }
    return (
      <UnstyledButton
        className="z-10"
        disabled={settingTargetStation !== null}
        onClick={(ev) => {
          ev.stopPropagation();
          setStationTarget(city);
        }}
      >
        <FaCheck className="mt-1 text-xs" />
      </UnstyledButton>
    );
  }

  return (
    <Box h="780px" w="1200px" className="relative">
      <BackgroundImage src={mapImage}>
        <Box h="780px" w="1200px"></Box>
      </BackgroundImage>
      {/* cities */}
      {game.cities.map((city, idx) => (
        <div
          key={idx}
          className={`${isCityClickable(city) ? 'cursor-pointer' : ''}`}
          onClick={() => handleCityClick(city)}
        >
          <MapCity
            {...city}
            isObjective={isObjective(city)}
            showName={showCityName(city)}
            claimedBy={findStation(city)?.player}
          >
            {adjacentStationSelected(city) && stationTargetSelectButton(city)}
          </MapCity>
        </div>
      ))}
      {/* tracks */}
      {game.tracks.map((track, trackIdx) => (
        <div
          key={track.id}
          onMouseEnter={() => setHoveredTrack(track)}
          onMouseLeave={() => setHoveredTrack(null)}
          className={`${isClaimable(track) ? 'cursor-pointer' : ''}`}
          onClick={() => handleTrackClick(track)}
        >
          <TrainTrack
            {...track}
            animation={animatedPath?.animation.find((x) => x.id === track.id)}
            claimedBy={findClaimedTrack(track)?.player}
          />
        </div>
      ))}
    </Box>
  );
}
