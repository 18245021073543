import {
  Alert,
  Box,
  Flex,
  Loader,
  MantineColor,
  Progress,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  FaBuilding,
  FaCheck,
  FaExclamationTriangle,
  FaLocationArrow,
  FaPlay,
  FaStar,
  FaTrain,
} from 'react-icons/fa';
import { Socket } from 'socket.io-client';
import { ApiException, apiGet, apiPost } from '../../services/api-service';
import { socketHandle } from '../../services/socket-service';
import {
  GameOverData,
  GameOverDataObjective,
  PlayerColor,
  PlayerData,
  PublicGameData,
} from '../../types/game';

interface Props {
  game: PublicGameData;
  currentPlayer: PlayerData;
  socket: Socket;
  setRevealedObjective: (objective: GameOverDataObjective | null) => void;
  revealedObjective: GameOverDataObjective | null;
}

export default function ScoreReveal({
  game,
  currentPlayer,
  socket,
  setRevealedObjective,
  revealedObjective,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [gameOverData, setGameOverData] = useState<GameOverData | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const results: GameOverData = await apiGet(`game/${game.id}/results`);
        setGameOverData(results);
      } catch (error) {
        if (error instanceof ApiException) {
          setError(error.message);
        } else {
          setError('Could not load data');
        }
      }
      setLoading(false);
    }

    loadData();
  }, [game.id]);

  useEffect(() => {
    socketHandle('score-reveal', socket, (data) => {
      setGameOverData(data.results);
      setRevealedObjective(data.lastRevealed);
    });
    return () => {
      socket.off('score-reveal');
    };
  }, [socket, gameOverData, setRevealedObjective]);

  // display an error if something went wrong
  if (error) {
    return (
      <Box className="p-2">
        <Alert
          icon={<FaExclamationTriangle size={16} />}
          title="Something went wrong"
          color="red"
        >
          {error}
        </Alert>
      </Box>
    );
  }

  // loader
  if (!gameOverData || loading) {
    return (
      <Flex className="p-3">
        <Text>Loading results...</Text>
        <Loader size="sm" className="ml-1" />
      </Flex>
    );
  }
  const partialRevealPlayer = gameOverData.players.find(
    (x) => x.status === 'revealing'
  );

  async function revealScore() {
    try {
      await apiPost(`game/${game.id}/reveal-score`, {
        playerCode: currentPlayer.code,
      });
    } catch (error) {}
  }

  async function revealNext() {
    if (!partialRevealPlayer) {
      return;
    }
    try {
      await apiPost(`game/${game.id}/reveal-score-advance`, {
        playerCode: currentPlayer.code,
        objectiveIdx: partialRevealPlayer.objectives.length,
      });
    } catch (error) {}
  }

  function getGameOverPlayer(color: PlayerColor) {
    return gameOverData!.players.find((player) => player.color === color)!;
  }

  function sumObjectiveScores(objectives: GameOverDataObjective[]) {
    return objectives.reduce((acc, x) => {
      if (x.completed) {
        return acc + x.points;
      } else {
        return acc - x.points;
      }
    }, 0);
  }

  function getObjectivesScore(color: PlayerColor) {
    const gameOverPlayer = getGameOverPlayer(color);
    if (gameOverPlayer.status !== 'pending') {
      return sumObjectiveScores(gameOverPlayer.objectives);
    }
    return null;
  }

  function formatObjectivesScore(color: PlayerColor) {
    const score = getObjectivesScore(color);
    if (score === null) {
      return <>?</>;
    }
    const sign = score < 0 ? '-' : '+';
    return (
      <>
        {sign}
        {Math.abs(score)}
      </>
    );
  }

  function getTotalScore(color: PlayerColor) {
    const player = game.players.find((x) => x.color === color)!;
    const gameOverPlayer = getGameOverPlayer(color);
    const stationsPoints = player.stations * 4;
    const objectivesPoints = getObjectivesScore(color) ?? 0;
    const bonusPoints =
      gameOverPlayer.longestTrain === gameOverData!.longestTrainLength ? 10 : 0;
    return stationsPoints + objectivesPoints + bonusPoints + player.score;
  }

  function currentPlayerName() {
    const results = getGameOverPlayer(currentPlayer.color);
    const fontColor = currentPlayer.color === 'black' ? 'text-white' : '';
    if (results.status === 'pending' && !partialRevealPlayer) {
      return (
        <UnstyledButton className="w-full cursor-pointer" onClick={revealScore}>
          <Text weight="bold" className={fontColor}>
            Reveal score <FaStar className="pt-1" />
          </Text>
        </UnstyledButton>
      );
    } else if (results.status === 'revealing') {
      if (currentPlayer.objectives.length === results.objectives.length) {
        return (
          <UnstyledButton
            className="w-full cursor-pointer"
            onClick={revealNext}
          >
            <Text weight="bold" className={fontColor}>
              Finish reveal <FaCheck className="pt-1" />
            </Text>
          </UnstyledButton>
        );
      }
      return (
        <UnstyledButton className="w-full cursor-pointer" onClick={revealNext}>
          <Text weight="bold" className={fontColor}>
            Reveal next <FaPlay className="pt-1" />
          </Text>
        </UnstyledButton>
      );
    }
    return (
      <Text weight="bold" className={fontColor}>
        {currentPlayer.name}
      </Text>
    );
  }

  function getMantineColor(color: PlayerColor): MantineColor {
    if (color === 'black') {
      return 'dark';
    }
    return color;
  }

  const highestScore = game.players
    .map((x) => getTotalScore(x.color))
    .reduce((acc, x) => Math.max(acc, x));
  const maxScore = highestScore > 200 ? highestScore : 200;

  // actual game over display
  return (
    <Flex
      className="pt-2 px-3 h-full max-h-48"
      direction="column"
      justify="space-between"
    >
      {game.players.map((player) => (
        <Flex className="pb-1" key={player.color}>
          <div
            className={`w-56 border player-card-${player.color} rounded-md overflow-hidden px-2 mx-2`}
          >
            {player.color === currentPlayer.color ? (
              currentPlayerName()
            ) : (
              <>{player.name}</>
            )}
          </div>
          <div className="w-16 text-right">
            {player.score}
            <Tooltip label="Initial score">
              <span>
                <FaStar className="ml-1" />
              </span>
            </Tooltip>
          </div>
          <div className="w-16 text-right">
            {getGameOverPlayer(player.color).longestTrain ===
              gameOverData.longestTrainLength && <Text span>+10</Text>}
            <Tooltip
              label={`Longest train: ${
                getGameOverPlayer(player.color).longestTrain
              }`}
            >
              <span>
                <FaTrain className="ml-1" />
              </span>
            </Tooltip>
          </div>
          <div className="w-16 text-right">
            <Text span>+{player.stations * 4}</Text>
            <Tooltip label={`Saved stations: ${player.stations}`}>
              <span>
                <FaBuilding className="ml-1" />
              </span>
            </Tooltip>
          </div>
          <div className="w-16 text-right">
            <Text span>{formatObjectivesScore(player.color)}</Text>
            <Tooltip label={`Completed missions`}>
              <span>
                <FaLocationArrow className="ml-1" />
              </span>
            </Tooltip>
          </div>
          <div className="w-80 pl-4 pt-2">
            <Progress
              value={(getTotalScore(player.color) / maxScore) * 100}
              color={getMantineColor(player.color)}
            ></Progress>
          </div>
          <div className="w-10 text-right">{getTotalScore(player.color)}</div>
          <div className="pl-2 flex-grow">
            {partialRevealPlayer?.color === player.color &&
              revealedObjective && (
                <span
                  className={
                    revealedObjective.completed
                      ? 'text-green-700'
                      : 'text-red-700'
                  }
                >
                  {revealedObjective.completed
                    ? `+${revealedObjective.points}`
                    : `-${revealedObjective.points}`}{' '}
                  {revealedObjective.cityA} - {revealedObjective.cityB}
                </span>
              )}
          </div>
        </Flex>
      ))}
    </Flex>
  );
}
