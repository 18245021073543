import { CloseButton, Flex, Text } from '@mantine/core';
import React, { useState } from 'react';
import { FaCity, FaHardHat, FaMap } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import {
  CardColor,
  CityData,
  PlayerData,
  PlayerGameData,
  PublicGameData,
  StationData,
} from '../../types/game';
import ConstructionRecipes from './ConstructionRecipes';

interface Props {
  gameId: string;
  close: () => void;
  city: CityData;
  player: PlayerData;
  station?: StationData;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function CityDetails({
  player,
  gameId,
  close,
  city,
  station,
  setGameData,
  setPlayerData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const requiredCards = 4 - player.stations;
  const canBuildStation = player.stations > 0;

  async function handleChosenCombination(combination: CardColor[]) {
    setIsLoading(true);
    try {
      const gameData = await apiPost<PlayerGameData>(
        `game/${gameId}/place-station`,
        {
          playerCode: player.code,
          city: city.name,
          chosenCards: combination,
        }
      );
      setGameData(gameData.game);
      setPlayerData(gameData.player);
    } catch (error) {}
    setIsLoading(false);
  }

  function formatRequiredCards() {
    if (player.stations === 0) {
      return <>You have no stations left</>;
    }
    return <>{requiredCards}</>;
  }

  function stationDestination() {
    if (!station?.toCity) {
      return <i>no destination set</i>;
    }
    return <b>{station.toCity}</b>;
  }

  if (station?.player === player.color) {
    return (
      <div className="pb-3">
        <Flex align="center">
          <Text size="lg" weight="bold" className="flex-grow">
            <FaCity /> Station in {city.name}
          </Text>
          <CloseButton size="lg" onClick={close} />
        </Flex>
        <Text>
          <FaMap /> Destination: {stationDestination()}
        </Text>
      </div>
    );
  } else if (station) {
    return <i>{city.name} already has a station</i>;
  }

  return (
    <div className="pb-3">
      <Flex align="center">
        <Text size="lg" weight="bold" className="flex-grow">
          <FaHardHat /> Build station in{' '}
          <Text span weight="bold">
            {city.name}
          </Text>{' '}
        </Text>
        <CloseButton size="lg" onClick={close} />
      </Flex>
      <Text>
        <Text span weight="bold">
          Required cards
        </Text>
        : {formatRequiredCards()}
      </Text>
      {canBuildStation && (
        <ConstructionRecipes
          playerCards={player.cards}
          color={'rainbow'}
          requiredLocomotives={0}
          count={requiredCards}
          onChosenCombination={handleChosenCombination}
          showLoading={isLoading}
        />
      )}
    </div>
  );
}
