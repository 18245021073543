// map
export const cardColors = [
  'red',
  'orange',
  'yellow',
  'white',
  'blue',
  'green',
  'pink',
  'black',
  'rainbow',
] as const;

export const trainColors = [...cardColors, 'gray'] as const;

export type CardColor = (typeof cardColors)[number];
export type TrackColor = (typeof trainColors)[number];

export interface CityData {
  top: number;
  left: number;
  name: string;
}

export interface TrackData {
  id: number;
  cityA: string;
  cityB: string;
  segments: [number, number, number][];
  color: TrackColor;
  requiredLocomotives: number;
}

// players
export const playerColors = [
  'red',
  'green',
  'blue',
  'yellow',
  'black',
] as const;

export type PlayerColor = (typeof playerColors)[number];

export interface PublicPlayerData {
  name: string;
  objectivesCount: number;
  cardsCount: number;
  trains: number; // remaining trains
  color: PlayerColor;
  score: number;
  stations: number;
}

// game data
export interface StationData {
  player: PlayerColor;
  city: string;
  toCity: string | null;
}

export interface ObjectiveData {
  cityA: string;
  cityB: string;
  points: number;
}

export const moveTypes = [
  'pick-trains',
  'pick-objectives',
  'place-trains',
  'place-station',
] as const;

// chat
export const chatEventType = [
  ...moveTypes,
  'chosen-objectives',
  'system',
] as const;

export type ChatEventType = (typeof chatEventType)[number];

export interface ChatEvent {
  type: ChatEventType;
}

export interface EventPickTrains extends ChatEvent {
  type: 'pick-trains';
  deckPicks: number;
  shownPicks: CardColor[];
}
export interface EventPickObjectives extends ChatEvent {
  type: 'pick-objectives';
  count: number;
}
export interface EventPlaceTrains extends ChatEvent {
  type: 'place-trains';
  track: {
    cityA: string;
    cityB: string;
    color: TrackColor;
  };
  usedCards: CardColor[];
}
export interface EventPlaceStation extends ChatEvent {
  type: 'place-station';
  city: string;
  usedCards: CardColor[];
}
export interface EventChosenObjectives extends ChatEvent {
  type: 'chosen-objectives';
  count: number;
}
export interface EventSystem extends ChatEvent {
  type: 'system';
}

export interface ChatMessageData {
  player: string | null;
  text: string | null;
  event:
    | EventPickObjectives
    | EventPickTrains
    | EventPlaceStation
    | EventPlaceTrains
    | EventChosenObjectives
    | EventSystem
    | null;
}

export interface PlayerData {
  code: string;
  name: string;
  color: PlayerColor;
  trains: number;
  stations: number;
  objectives: ObjectiveData[];
  pendingObjectives: ObjectiveData[];
  cards: CardColor[];
  score: number;
}

export interface ClaimedTrack {
  id: number;
  cityA: string;
  cityB: string;
  color: TrackColor;
  player: PlayerColor;
}

export type ObjectivePath = {
  type: 'track';
  id: number;
  cityA: string;
  cityB: string;
}[];

export interface GameOverDataObjective {
  cityA: string;
  cityB: string;
  completed: boolean;
  points: number;
  revealed: boolean;
  path: ObjectivePath | null;
}

export interface GameOverData {
  longestTrainLength: number;
  players: {
    objectives: GameOverDataObjective[];
    longestTrain: number;
    color: PlayerColor;
    status: 'pending' | 'revealing' | 'revealed';
  }[];
}

export interface BaseGameData {
  id: string;
  cities: CityData[];
  tracks: TrackData[];
  turnColor: PlayerColor;
  turnData: {
    pendingPickedTrains: number | null;
    deckPicks: number | null;
    shownPicks: CardColor[] | null;
  };
  lastTurnColor: PlayerColor | null;
  claimedTracks: ClaimedTrack[];
  stations: StationData[];
  shownTrainCards: CardColor[];
  isOver: boolean;
}

export interface PublicGameData extends BaseGameData {
  players: PublicPlayerData[];
  trainsDeckSize: number;
}

export interface PlayerGameData {
  player: PlayerData; // current player
  game: PublicGameData;
}

export interface HiddenGameData extends BaseGameData {
  players: PlayerData[];
  trainsDeck: CardColor[];
  objectivesDeck: ObjectiveData[];
  longObjectives: ObjectiveData[];
  discardedTrains: CardColor[];
  gameOverData: GameOverData | null;
}
