import React from 'react';
import { PlayerColor, TrackColor } from '../../types/game';

interface Props extends React.PropsWithChildren {
  left: number;
  top: number;
  rot: number;
  color?: TrackColor;
  claimedBy?: PlayerColor;
  className?: string;
  style?: React.CSSProperties;
}

export default function TrainSegment({
  left,
  top,
  rot,
  children,
  color,
  className,
  style,
  claimedBy,
}: Props) {
  color = claimedBy ?? color ?? 'gray';
  if (rot > 90 && rot < 270) {
    rot -= 180;
  }
  const claimColor = claimedBy === 'black' ? 'border-gray-200' : '';
  const segmentColor = claimedBy
    ? `train-player-${claimedBy}`
    : `train-segment-${color}`;
  return (
    <div
      className={`${className ?? ''} ${segmentColor}
        ${
          claimedBy
            ? ''
            : 'opacity-50 group-hover:border-2 group-hover:opacity-100'
        }
        border text-xs 
        flex items-center justify-center`}
      style={{
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        rotate: `${rot}deg`,
        width: '44px',
        height: '16px',
        ...style,
      }}
    >
      {claimedBy && (
        <div
          style={{ height: '10px' }}
          className={`w-full border-t border-b flex justify-around ${claimColor}`}
        >
          <div className={`border-r-2 ${claimColor}`}></div>
          <div className={`border-r-2 ${claimColor}`}></div>
          <div className={`border-r-2 ${claimColor}`}></div>
        </div>
      )}
      {children}
    </div>
  );
}
