import { Button, Flex, FocusTrap, Popover, TextInput } from '@mantine/core';
import { useMouse } from '@mantine/hooks';
import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from 'react';
import { FaSave } from 'react-icons/fa';
import { CityData } from '../../types/game';

interface Props {
  placeCity?: (city: CityData) => void;
}

export default function CityPlacer({ placeCity }: Props) {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [dragging, setDragging] = useState(false);
  const { x, y } = useMouse();
  const [lastX, setLastX] = useState(x);
  const [lastY, setLastY] = useState(y);
  const [settingName, setSettingName] = useState(false);
  const [cityName, setCityName] = useState('');

  function handleMouseDown(event: ReactMouseEvent) {
    if (event.button === 0) {
      setDragging(true);
      setLastX(x);
      setLastY(y);
      setSettingName(false);
    } else {
      setDragging(false);
    }
  }

  useEffect(() => {
    // listens for enter to place the city
    const enterListener = (ev: KeyboardEvent) => {
      if (ev.key === 'Enter' && !settingName) {
        setSettingName(true);
        setCityName('');
      }
    };
    window.addEventListener('keypress', enterListener);
    return () => {
      window.removeEventListener('keypress', enterListener);
    };
  });
  useEffect(() => {
    // listen to left clicks
    const mouseListener = (ev: MouseEvent) => {
      if (ev.button === 0) {
        setDragging(false);
      }
    };
    window.addEventListener('mouseup', mouseListener);
    return () => {
      window.removeEventListener('mouseup', mouseListener);
    };
  });

  if (dragging && Math.abs(lastX - x) + Math.abs(lastY - y) > 0) {
    setTop(top + y - lastY);
    setLeft(left + x - lastX);
    setLastX(x);
    setLastY(y);
  }

  function handleSaveCity(event: React.FormEvent) {
    event.preventDefault();
    setSettingName(false);
    if (placeCity) {
      placeCity({ top, left, name: cityName });
    }
  }

  return (
    <Popover opened={settingName}>
      <Popover.Dropdown>
        <FocusTrap>
          <form onSubmit={handleSaveCity}>
            <Flex>
              <TextInput
                placeholder="City name..."
                value={cityName}
                onChange={(ev) => setCityName(ev.currentTarget.value)}
              ></TextInput>
              <Button className="ml-1" type="submit">
                <FaSave></FaSave>
              </Button>
            </Flex>
          </form>
        </FocusTrap>
      </Popover.Dropdown>
      <Popover.Target>
        <div
          className="absolute opacity-50 border rounded-full bg-blue-400 border-blue-800"
          onMouseDown={handleMouseDown}
          style={{
            left,
            top,
            height: '17px',
            width: '17px',
          }}
        ></div>
      </Popover.Target>
    </Popover>
  );
}
