import React, { useEffect, useState } from 'react';
import { PlayerColor, TrackData } from '../../types/game';
import { TrackAnimation } from './GameMap';
import TrainSegment from './TrainSegment';

interface Props extends TrackData, React.PropsWithChildren {
  claimedBy?: PlayerColor;
  animation?: TrackAnimation;
}

export default function TrainTrack({
  segments,
  color,
  requiredLocomotives,
  children,
  claimedBy,
  animation,
  cityA,
}: Props) {
  const [segmentsAnimation, setSegmentsAnimation] = useState(() =>
    segments.map((_) => 0)
  );
  // redraws the tracks once to play a new animation
  const [lastAnimation, setLastAnimation] = useState<{
    id: number;
    animation: TrackAnimation | null;
  }>({ id: 0, animation: null });
  useEffect(() => {
    if (!animation || animation === lastAnimation.animation) {
      return;
    }
    const newSegmentsAnimation: number[] = [];
    for (let idx = 0; idx < segments.length; idx++) {
      newSegmentsAnimation.push(animation.delay + idx);
    }
    if (animation.cityA !== cityA) {
      newSegmentsAnimation.reverse();
    }
    setSegmentsAnimation(newSegmentsAnimation);
    setLastAnimation({
      id: lastAnimation.id + 1,
      animation,
    });
  }, [animation, segments.length, cityA, lastAnimation]);
  return (
    <div className="group">
      {segments.map((segment, idx) => (
        <TrainSegment
          key={`${idx}-${lastAnimation.id}`}
          left={segment[0]}
          top={segment[1]}
          rot={segment[2]}
          style={{
            animationDelay: `${segmentsAnimation[idx] * 150 + 500}ms`,
            animationDuration: '1s',
            animationName: animation ? 'train-segment-glow' : undefined,
          }}
          color={
            color !== 'rainbow' || idx < requiredLocomotives ? color : 'gray'
          }
          claimedBy={claimedBy}
        >
          {idx === 0 && children}
        </TrainSegment>
      ))}
    </div>
  );
}
