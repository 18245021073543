import { Flex, Text } from '@mantine/core';
import React from 'react';
import { ObjectiveData } from '../../types/game';

interface Props extends React.PropsWithChildren {
  objective: ObjectiveData;
  complete: boolean;
}

export default function Objective({ objective, children, complete }: Props) {
  return (
    <Flex
      className={`border rounded-lg h-14 items-center
      ${
        complete
          ? 'border-green-700 bg-green-200'
          : 'border-yellow-700 bg-yellow-200'
      }`}
    >
      <div className="w-14 inline-flex items-center justify-center">
        {children}
      </div>
      <Text className="p-3 flex-grow" size="md">
        {objective.cityA} - {objective.cityB}
      </Text>
      <div
        className={`w-14 border-l  h-full flex items-center justify-center
        ${complete ? 'border-green-500' : 'border-yellow-500'}`}
      >
        {objective.points}
      </div>
    </Flex>
  );
}
