import { List, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import Objectives from './Objectives';
import {
  PlayerData,
  ObjectiveData,
  TrackData,
  CityData,
  PublicGameData,
} from '../../types/game';
import { FaExclamationTriangle, FaSearch } from 'react-icons/fa';
import PlayerCards from './PlayerCards';
import TrackDetails from './TrackDetails';
import CityDetails from './CityDetails';

interface Props {
  game: PublicGameData;
  player: PlayerData;
  isConnected: boolean;
  setHoveredObjective: (objective: ObjectiveData | null) => void;
  selectedTrack: TrackData | null;
  clearSelectedTrack: () => void;
  selectedCity: CityData | null;
  setSelectedCity: (city: CityData | null) => void;
  pinnedObjectives: ObjectiveData[];
  setPinnedObjectives: (objectives: ObjectiveData[]) => void;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function Inventory({
  game,
  player,
  isConnected,
  setHoveredObjective,
  selectedTrack,
  clearSelectedTrack,
  selectedCity,
  setSelectedCity,
  pinnedObjectives,
  setPinnedObjectives,
  setGameData,
  setPlayerData,
}: Props) {
  const turnColor = game.turnColor;
  const playerStations = game.stations.filter((x) => x.player === player.color);

  function formatStatus() {
    const turnData = game.turnData;
    const lastTurnColor = game.lastTurnColor;
    if (!isConnected) {
      return (
        <>
          <FaExclamationTriangle className="text-red-700" /> Connection lost,
          attempting to reconnect...
        </>
      );
    }
    if (game.isOver) {
      return <>The game is over!</>;
    }
    if (turnColor === player.color) {
      if (player.pendingObjectives.length > 0) {
        return (
          <>
            It's your {lastTurnColor ? 'last ' : ''}turn, choose which
            objectives you want to keep!
          </>
        );
      }
      if (turnData.pendingPickedTrains) {
        return <>You must still pick {turnData.pendingPickedTrains} trains!</>;
      }
      if (lastTurnColor) {
        return (
          <>
            This is your last turn!{' '}
            {lastTurnColor !== player.color
              ? `${lastTurnColor} will be the last player to play`
              : ''}
          </>
        );
      }
      return <>It's your turn!</>;
    } else {
      if (lastTurnColor) {
        const whoLastTurn =
          lastTurnColor === player.color ? 'you' : lastTurnColor;
        return (
          <>
            Current player: {turnColor}, {whoLastTurn} will have the last move.
          </>
        );
      }
      return <>Current player: {turnColor}</>;
    }
  }

  function statusColor() {
    if (!isConnected) {
      return 'bg-gray-200';
    }
    if (game.isOver) {
      return 'bg-green-200';
    }
    return turnColor === player.color ? 'status-player-turn' : 'bg-red-200';
  }

  return (
    <div className="w-full h-full p-3">
      <div
        className={`-mx-3 -mt-3  p-3 border-b border-gray-600 mb-3 ${statusColor()}`}
      >
        <Text size="xl">{formatStatus()}</Text>
      </div>
      <div>
        <PlayerCards cards={player.cards} />
        {selectedTrack && player.pendingObjectives.length === 0 && (
          <TrackDetails
            playerCode={player.code}
            gameId={game.id}
            close={clearSelectedTrack}
            track={selectedTrack}
            playerCards={player.cards}
            setGameData={setGameData}
            setPlayerData={setPlayerData}
          />
        )}
        {playerStations.length > 0 && (
          <>
            <Text size="xl" weight="bold">
              Your stations:
            </Text>
            <List>
              {playerStations.map((station) => (
                <List.Item key={station.city}>
                  <UnstyledButton
                    onClick={() =>
                      setSelectedCity(
                        game.cities.find((x) => x.name === station.city)!
                      )
                    }
                  >
                    {station.city}
                    <FaSearch className="ml-1" />
                  </UnstyledButton>
                </List.Item>
              ))}
            </List>
          </>
        )}
        {selectedCity && player.pendingObjectives.length === 0 && (
          <CityDetails
            setGameData={setGameData}
            setPlayerData={setPlayerData}
            station={game.stations.find((x) => x.city === selectedCity.name)}
            gameId={game.id}
            close={() => setSelectedCity(null)}
            city={selectedCity}
            player={player}
          />
        )}
        <Objectives
          game={game}
          player={player}
          setHoveredObjective={setHoveredObjective}
          pinnedObjectives={pinnedObjectives}
          setPinnedObjectives={setPinnedObjectives}
          setGameData={setGameData}
          setPlayerData={setPlayerData}
        />
      </div>
    </div>
  );
}
