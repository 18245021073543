import { Tooltip } from '@mantine/core';
import React from 'react';
import { CityData, PlayerColor } from '../../types/game';

interface Props extends CityData, React.PropsWithChildren {
  isObjective?: boolean;
  showName?: boolean;
  claimedBy?: PlayerColor;
}

export default function MapCity({
  left,
  top,
  name,
  children,
  isObjective,
  showName,
  claimedBy,
}: Props) {
  return (
    <Tooltip withArrow opened={showName || undefined} label={name}>
      <div
        className={`absolute border rounded-full hover:border-2 flex items-center justify-center
        ${
          isObjective
            ? 'bg-blue-400 border-blue-800 hover:bg-blue-300'
            : 'bg-orange-400 border-orange-800 hover:bg-orange-300'
        }`}
        style={{
          left,
          top,
          height: '17px',
          width: '17px',
        }}
      >
        {claimedBy && (
          <div
            className={`absolute border rounded-full w-[13px] h-[13px]
            train-player-${claimedBy} flex items-center justify-center`}
          >
            <div className={`border rounded-full w-[7px] h-[7px]`}></div>
          </div>
        )}
        {children}
      </div>
    </Tooltip>
  );
}
