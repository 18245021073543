import { CloseButton, Flex, Text } from '@mantine/core';
import React, { useState } from 'react';
import { FaHardHat } from 'react-icons/fa';
import { apiPost } from '../../services/api-service';
import {
  CardColor,
  PlayerData,
  PlayerGameData,
  PublicGameData,
  TrackData,
} from '../../types/game';
import ConstructionRecipes from './ConstructionRecipes';

interface Props {
  track: TrackData;
  close: () => void;
  playerCards: CardColor[];
  gameId: string;
  playerCode: string;
  setGameData: (data: PublicGameData) => void;
  setPlayerData: (data: PlayerData) => void;
}

export default function TrackDetails({
  track,
  close,
  playerCards,
  gameId,
  playerCode,
  setGameData,
  setPlayerData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  function formatColor() {
    const color = ['gray', 'rainbow'].includes(track.color)
      ? 'Any color'
      : track.color;
    if (track.requiredLocomotives) {
      return (
        <>
          {color} with at least {track.requiredLocomotives} rainbow train(s)
        </>
      );
    }
    return <>{color}</>;
  }

  async function handleChosenCombination(combination: CardColor[]) {
    setIsLoading(true);
    try {
      const gameData = await apiPost<PlayerGameData>(
        `game/${gameId}/place-trains`,
        {
          trackId: track.id,
          chosenCards: combination,
          playerCode,
        }
      );
      setGameData(gameData.game);
      setPlayerData(gameData.player);
      close();
    } catch (error) {}
    setIsLoading(false);
  }

  return (
    <div className="pb-3">
      <Flex align="center">
        <Text size="lg" weight="bold" className="flex-grow">
          <FaHardHat /> Connect{' '}
          <Text span weight="bold">
            {track.cityA}
          </Text>{' '}
          and{' '}
          <Text span weight="bold">
            {track.cityB}
          </Text>
        </Text>
        <CloseButton size="lg" onClick={close} />
      </Flex>
      <Text>
        <Text span weight="bold">
          Color
        </Text>
        : {formatColor()}
      </Text>
      <ConstructionRecipes
        playerCards={playerCards}
        color={track.color === 'gray' ? 'rainbow' : track.color}
        requiredLocomotives={track.requiredLocomotives}
        count={track.segments.length}
        onChosenCombination={handleChosenCombination}
        showLoading={isLoading}
      />
    </div>
  );
}
