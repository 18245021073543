import {
  ColorSwatch,
  Group,
  Input,
  LoadingOverlay,
  ScrollArea,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CardColor, cardColors } from '../../types/game';

interface Props {
  color: CardColor;
  count: number;
  requiredLocomotives: number;
  playerCards: CardColor[];
  onChosenCombination: (combination: CardColor[]) => void;
  showLoading: boolean;
}

export default function ConstructionRecipes({
  color,
  count,
  requiredLocomotives,
  playerCards,
  onChosenCombination,
  showLoading,
}: Props) {
  requiredLocomotives ??= 0;
  const [selectedColor, setSelectedColor] = useState<CardColor>(color);
  useEffect(() => {
    setSelectedColor(color);
  }, [color]);
  const mantineTheme = useMantineTheme();

  const cardsByType: { [color: string]: number } = {};
  playerCards.forEach((card) => {
    cardsByType[card] ??= 0;
    cardsByType[card] += 1;
  });

  /**
   * checks if the player has sufficient cards of this color to build the track
   */
  function sufficientColoredCards(cardColor: CardColor) {
    if (cardColor === 'rainbow') {
      return cardsByType['rainbow'] >= count;
    }
    let rainbowCards = cardsByType['rainbow'] ?? 0;
    const colorCards = cardsByType[cardColor] ?? 0;
    if (color === 'rainbow' && colorCards === 0) {
      return false;
    }
    return (
      rainbowCards >= requiredLocomotives && rainbowCards + colorCards >= count
    );
  }

  let possibleColors =
    color === 'rainbow' && requiredLocomotives < count ? cardColors : [color];
  possibleColors = possibleColors.filter((x) => sufficientColoredCards(x));

  if (possibleColors.length === 0) {
    return <Text italic>You don't have enough cards to build this track</Text>;
  }

  function generateCombinations(color: CardColor): CardColor[][] {
    const rainbowCards = cardsByType['rainbow'] ?? 0;
    const colorCards = cardsByType[color] ?? 0;
    if (color === 'rainbow') {
      if (rainbowCards >= count) {
        return [Array(count).fill(color)];
      }
      return [];
    }
    const combinations: CardColor[][] = [];
    const minLocomotives = Math.max(requiredLocomotives, count - colorCards);
    let maxLocomotives = Math.min(count, rainbowCards);
    for (let i = minLocomotives; i <= maxLocomotives; i++) {
      combinations.push([
        ...Array(i).fill('rainbow'),
        ...Array(count - i).fill(color),
      ]);
    }
    return combinations;
  }

  function shownCombinations(): CardColor[][] {
    if (selectedColor === 'rainbow') {
      // generate combinations which require no more rainbow than necessary
      const generatedColors = possibleColors.filter(
        (color) => cardsByType[color] !== undefined
      );
      // prioritize more frequent cards
      const cardsPriority = { ...cardsByType };
      cardsPriority['rainbow'] = -1;
      generatedColors.sort(
        (colorA, colorB) => cardsPriority[colorB] - cardsPriority[colorA]
      );
      return generatedColors.flatMap((color) => {
        const colorCombinations = generateCombinations(color);
        if (colorCombinations.length > 0) {
          return [colorCombinations[0]];
        }
        return [];
      });
    }
    return generateCombinations(selectedColor);
  }

  const colorsMap: { [color in CardColor]?: string } = {
    ...cardColors.reduce(
      (obj, color) => ({
        ...obj,
        [color]: mantineTheme.colors[color]?.[6],
      }),
      {}
    ),
    pink: mantineTheme.colors.grape[4],
    black: mantineTheme.colors.gray[8],
    yellow: mantineTheme.colors.yellow[4],
    white: '#fff',
  };
  return (
    <div className="relative">
      <LoadingOverlay visible={showLoading} />
      {possibleColors.length > 1 && (
        <Input.Wrapper label="Choose a color" className="max-w-xs">
          <Group spacing={8}>
            {possibleColors
              .filter((x) => x !== 'rainbow')
              .map((color) => (
                <Tooltip label={`Build with ${color} cards`} key={color}>
                  <ColorSwatch
                    component="button"
                    color={colorsMap[color]!}
                    onClick={() => setSelectedColor(color)}
                    sx={{
                      color: color === 'white' ? '#000' : '#fff',
                      cursor: 'pointer',
                    }}
                    type="button"
                  >
                    {selectedColor === color && <FaCheck />}
                  </ColorSwatch>
                </Tooltip>
              ))}
          </Group>
        </Input.Wrapper>
      )}
      <ScrollArea className="mt-2 h-32">
        {shownCombinations().map((combination, idx) => (
          <div key={idx} className="w-full flex justify-center">
            <Group
              key={idx}
              spacing="sm"
              className="group pb-2 cursor-pointer"
              onClick={() => onChosenCombination(combination)}
            >
              {combination.map((color, cardIdx) => (
                <div
                  key={cardIdx}
                  className={`border shown-card-${color} w-6 h-8 rounded`}
                ></div>
              ))}
            </Group>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
}
